.cursor-pointer {
    cursor: pointer!important;
}

/* Style pour le tableau */
.custom-table {
    background-color: #434345 !important; /* Fond gris foncé doux */
    color: #e0e0e0 !important;           /* Texte gris clair */
    border-collapse: separate !important;
    border-spacing: 0 !important;
    width: 100%;
    border-radius: 8px !important;       /* Coins arrondis */
    overflow: hidden;
}

.custom-table th {
    background-color: #494950 !important; /* En-tête gris légèrement plus clair */
    color: #ffffff !important;           /* Texte blanc */
    text-align: left;
    padding: 10px !important;
    font-weight: bold;
}

.custom-table tbody tr:hover {
    background-color: #989595 !important; /* Fond au survol gris moyen */
    cursor: pointer !important;
}

.custom-table tbody td {
    padding: 10px !important;
    border-bottom: 1px solid #4a4a4d !important; /* Séparation douce des lignes */
}

.table-row:last-child td {
    border-bottom: none !important;
}

.selected-row {
    background-color: #f0f8ff; /* Couleur de fond pour la ligne sélectionnée */
    color: #4883c3; /* Couleur du texte pour la ligne sélectionnée */
    font-weight: bold; /* Optionnel : met en gras le texte */
}

.selected-row:hover {
    background-color: #e0f0ff; /* Légère variation au survol */
}


/* Style pour l'input */
.custom-input {
    background-color: #2a2a2e !important; /* Fond assorti au tableau */
    color: #e0e0e0 !important;           /* Texte gris clair */
    border: 1px solid #3d3d42 !important; /* Bordure douce gris foncé */
    border-radius: 8px !important;       /* Coins arrondis */
    padding: 10px !important;
}

.custom-input::placeholder {
    color: #a0a0a5 !important; /* Placeholder gris clair */
}

.custom-input:focus {
    outline: none !important;
    border-color: #56565b !important; /* Bordure active gris clair */
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.15) !important; /* Effet focus doux */
}

.certification-detail {
    background-color: #2a2a2e; /* Fond doux pour s'adapter au thème */
    color: #e0e0e0;           /* Texte clair */
    padding: 20px;
    border-radius: 8px;       /* Coins arrondis */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Effet de profondeur */
}

.certification-detail p {
    margin-bottom: 10px;
}

.nav-tabs .nav-link {
    background-color: #3a3a3e; /* Fond des onglets */
    color: #e0e0e0;            /* Couleur du texte */
    border: none;
    border-radius: 0;          /* Suppression des coins arrondis */
}

.nav-tabs .nav-link.active {
    background-color: #414145; /* Fond actif */
    color: #fff;               /* Texte blanc */
    font-weight: bold;
}

.documents-tab img.document-preview {
    max-width: 100%;   /* Les images ne dépassent pas la largeur de la page */
    max-height: 300px; /* Taille max en hauteur pour afficher plusieurs documents */
    cursor: pointer;   /* Curseur pointeur pour indiquer l'agrandissement */
    border: 1px solid #4a4a4d; /* Bordure douce */
    border-radius: 4px;
    transition: transform 0.2s ease;
}

.documents-tab img.document-preview:hover {
    transform: scale(1.05); /* Effet de zoom au survol */
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
}

/* Modal */
.modal-content {
    background-color: #2a2a2e; /* Fond modal */
    border: none;
    border-radius: 8px;
}

.modal-body img {
    max-width: 100%;
    border-radius: 8px;
}
.custom-modal {
    color: white; /* Définit la couleur du texte globalement */
}

.custom-modal .nav-tabs .nav-link {
    color: white;
}

.custom-modal .nav-tabs .nav-link.active {
    background-color: rgba(255, 255, 255, 0.2); /* Active tab background */
    color: white;
}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
    background-color: #222; /* Optionnel : changer le fond pour plus de contraste */
}

.custom-modal p {
    color: white;
}


.equal-height-row {
    display: flex;
    flex-wrap: wrap; /* Permet un comportement responsive */
}

.equal-height-row > .col-lg-4 {
    display: flex;
    flex-direction: column;
}

.equal-height-row .user-stats-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}


/* Carte principale */
.user-stats-card {
    background-color: #2a2a2e !important;
    color: #e0e0e0;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #434345;
}

.user-stats-card .title {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
}

.custom-divider {
    border-top: 1px solid #4a4a4d;
}

.stat-row {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
}

.stat-label {
    font-size: 0.8rem;
    color: #c1c1c1;
}

.stat-value {
    font-size: 0.9rem;
    font-weight: bold;
    color: #ffffff;
}

.total-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-category {
    font-size: 0.9rem;
    color: #c1c1c1;
}

.total-value {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
}

/* Footer */
/* Footer aligné */
.footer {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: transparent;
    border-top: none;
}

/* Bouton d'actualisation */
.btn-refresh {
    background: none;
    border: none;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 1.2rem;
    transition: color 0.2s ease, transform 0.2s ease;
}

.btn-refresh:hover {
    color: #ffffff;
    transform: rotate(90deg);
}

.btn-refresh:focus {
    outline: none;
    color: #ffffff;
}

.globe-icon {
    color: #f0ad4e;
    font-size: 1.5rem;
}

/* diagram */
.card-chart {
    background-color: #2a2a2e !important; /* Fond sombre pour la carte */
    color: #e0e0e0 !important; /* Texte gris clair */
    border-radius: 8px !important; /* Coins arrondis */
    border: 1px solid #434345 !important; /* Bordure douce */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre douce */
}

.card-diagram-body {
    background-color: #434345 !important; /* Fond plus clair pour le corps de la carte */
    padding: 20px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 10px 20px;
    border-top: 1px solid #4a4a4d; /* Bordure supérieure pour séparer le footer */
}

.chart-legend {
    color: #e0e0e0;
}

.stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stats i {
    cursor: pointer;
    color: #e0e0e0;
    transition: color 0.3s ease;
}

.stats i:hover {
    color: #ffffff;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-refresh {
    background: none;
    border: none;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 1.2rem;
    transition: color 0.2s ease, transform 0.2s ease;
}

.btn-refresh:hover {
    color: #ffffff;
    transform: rotate(90deg);
}

.globe-icon {
    color: #f0ad4e;
    font-size: 1.5rem;
}
