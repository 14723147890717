/* ==========================================================================
   Credit Card Payment Section
   ========================================================================== */
.credit-card{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-holder {
    margin: 1em 0;
}

.img-box {
    /*padding-top: 20px;*/
    display: flex;
    justify-content: center;
}

.card-box {
    font-weight: 800;
    padding: 1em 1em;
    border-radius: 0.25em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.bg-news {
    background: -webkit-linear-gradient(70deg, #0c101b 40%, #58585A 40%);
    background: -o-linear-gradient(70deg, #0c101b 40%, #58585A 40%);
    background: -moz-linear-gradient(70deg, #0c101b 40%, #58585A 40%);
    background: linear-gradient(70deg, #0c101b 40%, #58585A 40%);
}

.inner-addon {
    position: relative;
}

.inner-addon .fas, .inner-addon .far {
    position: absolute;
    padding: 10px;
    pointer-events: none;
    color: #bcbdbd !important;
}

.credit-card .date-separator{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

@media (max-width: 768px) {
    .credit-card{
        height: auto;
        width: 100%;
    }
}
